<template>
    <div>
        <mdb-card class="mb-2  "
                  style="margin-top:-1rem">
            <mdb-card-body class="p-2">
                <mdb-btn tag="a"
                         size="sm"
                         outline="primary"
                         @click="$router.push('/product/new_bad/list/')">
                    <i class="fa fa-search"></i>
                    報廢單查詢
                </mdb-btn>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="分店"
                                    disabled
                                    v-model="storeList"
                                    @getValue="val=>new_bad.id_store=val"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="輸入日期"
                                    v-if="!new_bad.id"
                                    v-model="new_bad.day" />
                        <mdb-input label="輸入日期"
                                   disabled
                                   v-else
                                   :value="new_bad.day"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="建立人"
                                   disabled
                                   :value="new_bad.admin_name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="12">
                        <div class="rwd-table-dispersion">
                            <table class="table table-striped table-hover"
                                   style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width:3rem">#</th>
                                        <th>商品名稱</th>
                                        <th style="width:10rem">數量</th>
                                        <th style="width:5rem"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(i,ik) in new_bad.items"
                                        :key="i.id">
                                        <td>{{ik+1}}</td>
                                        <td>
                                            <div v-if="!new_bad.id">
                                                <small>{{productKeyList[i.id_product].code}}</small><br>
                                                {{productKeyList[i.id_product].name}}
                                            </div>
                                            <div v-else>
                                                <small>{{i.product_code}}</small><br>
                                                {{i.name_code}}
                                            </div>
                                        </td>
                                        <td>
                                            <mdb-input type="number"
                                                       class="my-0"
                                                       :min="1"
                                                       :max="productKeyList[i.id_product].stock"
                                                       v-model="i.num"
                                                       v-if="!new_bad.id"></mdb-input>
                                            {{ new_bad.id ? i.num : ''}}
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                <tbody v-if="!new_bad.id">
                                    <tr>
                                        <td class="d-none d-md-table-cell"
                                            style="color:#999"><i class="fa fa-plus"></i></td>
                                        <td>
                                            <mdb-row>
                                                <mdb-col sm="6"
                                                         md="7"
                                                         class="mb-1">
                                                    <SearchSelect placeholder="=== 請選擇 ==="
                                                                  v-model="new_id_product"
                                                                  :options="productList"></SearchSelect>
                                                </mdb-col>
                                                <mdb-col sm="6"
                                                         md="5">
                                                    <mdb-btn color="default"
                                                             size="sm"
                                                             @click="add_item()">
                                                        <i class="fa fa-plus"></i>
                                                        &nbsp;增加
                                                    </mdb-btn>
                                                </mdb-col>
                                            </mdb-row>
                                        </td>
                                        <td colspan="2">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4"
             v-if="!new_bad.id">
            <mdb-btn color="default"
                     @click="savedata()">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbSelect,
  mdbBtn,
} from "mdbvue";
import datepicker from "../../components/datepicker";
import SearchSelect from "../../components/SearchSelect";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbSelect,
    mdbCol,
    mdbBtn,
    datepicker,
    SearchSelect,
  },
  data() {
    return {
      new_id_product: "",
      new_bad: {},
      storeList: [],
      productList: [],
    };
  },
  mounted() {
    this.get_data();
  },
  computed: {
    productKeyList() {
      let vue = this,
        output = {};
      vue.productList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
  },
  methods: {
    get_data() {
      let vue = this,
        query = [
          {
            name: "new_bad",
          },
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: 1,
              },
            },
            sort: {
              name: 1,
            },
          },
        ];
      // 不是新增的話就載入id當條件
      if (
        typeof vue.$route.params.id != "undefined" &&
        vue.$route.params.id > 0
      ) {
        query[0].data = {
          id: {
            type: 0,
            value: vue.$route.params.id,
          },
        };
      }
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          // 新增
          if (
            typeof vue.$route.params.id != "undefined" &&
            vue.$route.params.id > 0
          ) {
            vue.new_bad = res.data.new_badList[0];
            vue.new_bad.items = JSON.parse(vue.new_bad.items);
          } else {
            vue.new_bad = res.data.new_badEmpty;
            vue.new_bad.id_store = vue.$store.state.id_store;
            vue.new_bad.day = vue.$moment().format("YYYY-MM-DD");
            vue.new_bad.id_admin = vue.$store.state.user.id;
            vue.new_bad.admin_name = vue.$store.state.user.name;
            vue.new_bad.items = [];
          }
          vue.storeList = res.data.storeList.map((item) => {
            item.value = item.id;
            item.text = item.name;
            item.selected = vue.new_bad.id_store == item.id;
            return item;
          });
          vue.get_product_list();
        });
    },
    // 取出商品清單
    get_product_list() {
      let vue = this;
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `product/get_store_product/?id_store=${vue.new_bad.id_store}`,
          },
        })
        .then((res) => {
          vue.productList = res.data.productList.map((item) => {
            item.key = item.id;
            item.value = `${item.name} ( ${item.code} )`;
            item.stock = parseInt(item.stock);
            return item;
          });
        });
    },
    add_item() {
      let vue = this,
        index = vue.new_bad.items.findIndex((item) => {
          return item.id_product == vue.new_id_product;
        });
      // 防止新增同一種商品
      if (index == -1) {
        this.new_bad.items.push({
          id_product: this.new_id_product,
          product_name: this.productKeyList[this.new_id_product].name,
          product_code: this.productKeyList[this.new_id_product].name,
          num: 1,
        });
      }

      this.new_id_product = "";
    },
    // 儲存資料
    savedata() {
      let vue = this,
        msg = "";
      switch (true) {
        case vue.new_bad.items.length < 1:
          msg = "請填寫 輸入新品不良商品";
          break;
      }
      if (msg == "") {
        vue.$swal.fire({
          icon: "question",
          title: "確定要送出嗎?",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm() {
            return vue.$store
              .dispatch("post_form", {
                payload: {
                  url: "product/new_bad_save",
                  data: {
                    data: JSON.stringify(vue.new_bad),
                  },
                },
              })
              .then((res) => {
                if (res.data.status == "ok") {
                  vue.$swal
                    .fire({
                      icon: "success",
                      title: "儲存完成!",
                      timer: 1500,
                      showConfirmButton: false,
                    })
                    .then(() => {
                      vue.new_bad.id = res.data.new_bad_id;
                      vue.$router.push(
                        `/product/new_bad/view/${res.data.new_bad_id}`
                      );
                    });
                }
              });
          },
        });
      } else {
        // console.info(vue.prod);
        vue.$swal.fire({
          icon: "error",
          title: msg,
          timer: 1500,
          showConfirmButton: false,
          toast: true,
        });
      }
    },
  },
};
</script>